import _extends from "/home/vsts/work/1/s/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import * as React from 'react';
function PlayButton(props) {
  return __jsx("svg", _extends({
    height: 60,
    viewBox: "0 0 60 60",
    width: 60
  }, props), __jsx("path", {
    d: "M30 0a30 30 0 1030 30A29.96 29.96 0 0030 0zm-7.533 42.667V17.333L42.6 30z",
    fill: "#fff"
  }));
}
export default PlayButton;